import React, { useState, useEffect } from "react";
import backgroundImage from "./assets/bg.png";
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const App = () => {
  const [address, setAddress] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const refId = queryParams.get("ref_id");
    if (refId) {
      handleReferral(refId);
    }
  }, []);

  function redirectToTwitter(referralCode) {
    const tweetMessage = `I've just joined @Berabot_ private beta, the first native Telegram trading bot on @Berachain 🐻⛓️\n\nJoin us: https://berabot.co?ref_id=${referralCode}\n\nSNIPE EM UP  ︻デ═一`;

    const encodedTweetMessage = encodeURIComponent(tweetMessage);

    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedTweetMessage}`;

    window.location.href = twitterUrl;
  }

  const generateRandomHexString = (length) => {
    let result = "";
    const characters = "0123456789abcdef";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  function isEthereumAddress(address) {
    if (!/^(0x)?[0-9a-fA-F]{40}$/.test(address)) {
      return false;
    } else if (
      /^(0x)?[0-9a-f]{40}$/.test(address) ||
      /^(0x)?[0-9A-F]{40}$/.test(address)
    ) {
      return true;
    } else {
      return true;
    }
  }

  const createUniqueReferralCode = async () => {
    let unique = false;
    let referralCode;

    while (!unique) {
      referralCode = generateRandomHexString(8);
      console.log("Checking referral code", referralCode);
      const { data, error } = await supabase
        .from("users")
        .select("referral_code")
        .eq("referral_code", referralCode)
        .maybeSingle();

      if (error && error.message.includes("No rows found")) {
        unique = true;
      } else if (error) {
        throw new Error("Error checking referral code uniqueness");
      }
      if (data === null) {
        unique = true;
      }
    }

    return referralCode;
  };

  const handleReferral = async (referralCode) => {
    const { data, error } = await supabase
      .from("users")
      .select("referrals")
      .eq("referral_code", referralCode)
      .single();

    if (error) {
      console.error("Error fetching referral data", error);
      return;
    }

    if (data) {
      const { referrals } = data;
      const { error: updateError } = await supabase
        .from("users")
        .update({ referrals: referrals + 1 })
        .eq("referral_code", referralCode);

      if (updateError) {
        console.error("Error updating referral count", updateError);
      }
    }
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
    setIsValid(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isEthereumAddress(address)) {
      const { data: existing, error: existingError } = await supabase
        .from("users")
        .select("address")
        .eq("address", address);

      if (existingError) {
        console.error("Error checking existing address", existingError);
        return;
      }

      if (existing.length > 0) {
        setIsValid(false);
        return;
      }

      const newReferralCode = await createUniqueReferralCode();
      const { error: insertError } = await supabase
        .from("users")
        .insert([{ address, referral_code: newReferralCode, referrals: 0 }]);

      if (insertError) {
        console.error("Error inserting new user", insertError);
      } else {
        setSubmitted(true);
        redirectToTwitter(newReferralCode);
      }
    } else {
      setIsValid(false);
    }
  };

  return (
    <div
      className="flex flex-col justify-between h-screen"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
      }}
    >
      <div className="flex flex-col items-center justify-center flex-grow">
        <div className="rounded p-8 text-center">
          <h1 className="text-7xl font-black mb-4 text-white">BERABOT</h1>
          <p className="text-xl text-white mb-8 font-bold">Join the waitlist</p>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col items-center md:w-[600px]"
          >
            <input
              className={`mb-4 px-4 py-2 border rounded w-full text-center ${
                !isValid && "border-red-500"
              }`}
              type="text"
              placeholder="Enter your EVM address"
              value={address}
              onChange={handleAddressChange}
            />
            {!isValid && (
              <p className="text-red-500">
                Please enter a valid Ethereum address.
              </p>
            )}
            <button
              className="px-8 py-2 bg-black md:w-[600px] text-white rounded text-lg font-bold"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <footer className="md:text-left text-center text-white p-4 font-bold ">
        © 2024 berabot, All Rights Reserved.
      </footer>
    </div>
  );
};

export default App;
